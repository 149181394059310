import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _471d82ca = () => interopDefault(import('..\\pages\\category\\index.vue' /* webpackChunkName: "pages/category/index" */))
const _50dd9eab = () => interopDefault(import('..\\pages\\media.vue' /* webpackChunkName: "pages/media" */))
const _5630d568 = () => interopDefault(import('..\\pages\\product\\index.vue' /* webpackChunkName: "pages/product/index" */))
const _5ffdd13c = () => interopDefault(import('..\\pages\\setNewPassword.vue' /* webpackChunkName: "pages/setNewPassword" */))
const _a6f36754 = () => interopDefault(import('..\\pages\\category\\searchResult\\index.vue' /* webpackChunkName: "pages/category/searchResult/index" */))
const _68dbee3c = () => interopDefault(import('..\\pages\\home\\api.vue' /* webpackChunkName: "pages/home/api" */))
const _9b36109e = () => interopDefault(import('..\\pages\\home\\catalogue.vue' /* webpackChunkName: "pages/home/catalogue" */))
const _2cf5cd2a = () => interopDefault(import('..\\pages\\home\\compareList.vue' /* webpackChunkName: "pages/home/compareList" */))
const _3d7727e8 = () => interopDefault(import('..\\pages\\home\\contact.vue' /* webpackChunkName: "pages/home/contact" */))
const _1e75f9b5 = () => interopDefault(import('..\\pages\\home\\contactPromoCollection.vue' /* webpackChunkName: "pages/home/contactPromoCollection" */))
const _721a695a = () => interopDefault(import('..\\pages\\home\\currentFlyer\\index.vue' /* webpackChunkName: "pages/home/currentFlyer/index" */))
const _23e3cfe5 = () => interopDefault(import('..\\pages\\home\\downloadCenter.vue' /* webpackChunkName: "pages/home/downloadCenter" */))
const _049cfb6d = () => interopDefault(import('..\\pages\\home\\EDMS.vue' /* webpackChunkName: "pages/home/EDMS" */))
const _533c6db6 = () => interopDefault(import('..\\pages\\home\\FAQs.vue' /* webpackChunkName: "pages/home/FAQs" */))
const _d2371874 = () => interopDefault(import('..\\pages\\home\\loyaltyProgram.vue' /* webpackChunkName: "pages/home/loyaltyProgram" */))
const _80a39a46 = () => interopDefault(import('..\\pages\\home\\myDetail\\index.vue' /* webpackChunkName: "pages/home/myDetail/index" */))
const _59435fc1 = () => interopDefault(import('..\\pages\\home\\newsLetter.vue' /* webpackChunkName: "pages/home/newsLetter" */))
const _17393cbc = () => interopDefault(import('..\\pages\\home\\privacyPolicy.vue' /* webpackChunkName: "pages/home/privacyPolicy" */))
const _60eec2e2 = () => interopDefault(import('..\\pages\\home\\projects.vue' /* webpackChunkName: "pages/home/projects" */))
const _2c306cf3 = () => interopDefault(import('..\\pages\\home\\refundReturns.vue' /* webpackChunkName: "pages/home/refundReturns" */))
const _1f075be7 = () => interopDefault(import('..\\pages\\home\\termsConditions.vue' /* webpackChunkName: "pages/home/termsConditions" */))
const _52fe027a = () => interopDefault(import('..\\pages\\home\\video.vue' /* webpackChunkName: "pages/home/video" */))
const _43346e02 = () => interopDefault(import('..\\pages\\home\\myDetail\\enquiryDetail.vue' /* webpackChunkName: "pages/home/myDetail/enquiryDetail" */))
const _3041028a = () => interopDefault(import('..\\pages\\home\\myDetail\\orderDetail.vue' /* webpackChunkName: "pages/home/myDetail/orderDetail" */))
const _4d1786c7 = () => interopDefault(import('..\\pages\\home\\myDetail\\components\\orderDetailPart.vue' /* webpackChunkName: "pages/home/myDetail/components/orderDetailPart" */))
const _bdb19ece = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _67d9a2f0 = () => interopDefault(import('..\\pages\\3D\\_id.vue' /* webpackChunkName: "pages/3D/_id" */))
const _d76182a8 = () => interopDefault(import('..\\pages\\article\\_type.vue' /* webpackChunkName: "pages/article/_type" */))
const _cb19b828 = () => interopDefault(import('..\\pages\\category\\_firstCategory\\index.vue' /* webpackChunkName: "pages/category/_firstCategory/index" */))
const _e4d3027a = () => interopDefault(import('..\\pages\\design\\_id.vue' /* webpackChunkName: "pages/design/_id" */))
const _be08439e = () => interopDefault(import('..\\pages\\news\\_newsName.vue' /* webpackChunkName: "pages/news/_newsName" */))
const _52e28a91 = () => interopDefault(import('..\\pages\\pdf\\_id.vue' /* webpackChunkName: "pages/pdf/_id" */))
const _a68c7690 = () => interopDefault(import('..\\pages\\product-builder\\_code.vue' /* webpackChunkName: "pages/product-builder/_code" */))
const _80928478 = () => interopDefault(import('..\\pages\\product-record\\_code.vue' /* webpackChunkName: "pages/product-record/_code" */))
const _529b7374 = () => interopDefault(import('..\\pages\\product\\_code.vue' /* webpackChunkName: "pages/product/_code" */))
const _f296f21a = () => interopDefault(import('..\\pages\\qrcode\\_id.vue' /* webpackChunkName: "pages/qrcode/_id" */))
const _4feb51eb = () => interopDefault(import('..\\pages\\render\\_id.vue' /* webpackChunkName: "pages/render/_id" */))
const _6eac5d52 = () => interopDefault(import('..\\pages\\category\\_firstCategory\\_secondCategory\\index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/index" */))
const _df26395a = () => interopDefault(import('..\\pages\\orderShare\\_aid\\_id.vue' /* webpackChunkName: "pages/orderShare/_aid/_id" */))
const _70810a91 = () => interopDefault(import('..\\pages\\category\\_firstCategory\\_secondCategory\\_thirdCategory\\index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/_thirdCategory/index" */))
const _9c4f3cbc = () => interopDefault(import('..\\pages\\orderShare\\_uid\\_aid\\_id.vue' /* webpackChunkName: "pages/orderShare/_uid/_aid/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/category",
    component: _471d82ca,
    name: "category"
  }, {
    path: "/media",
    component: _50dd9eab,
    name: "media"
  }, {
    path: "/product",
    component: _5630d568,
    name: "product"
  }, {
    path: "/setNewPassword",
    component: _5ffdd13c,
    name: "setNewPassword"
  }, {
    path: "/category/searchResult",
    component: _a6f36754,
    name: "category-searchResult"
  }, {
    path: "/home/api",
    component: _68dbee3c,
    name: "home-api"
  }, {
    path: "/home/catalogue",
    component: _9b36109e,
    name: "home-catalogue"
  }, {
    path: "/home/compareList",
    component: _2cf5cd2a,
    name: "home-compareList"
  }, {
    path: "/home/contact",
    component: _3d7727e8,
    name: "home-contact"
  }, {
    path: "/home/contactPromoCollection",
    component: _1e75f9b5,
    name: "home-contactPromoCollection"
  }, {
    path: "/home/currentFlyer",
    component: _721a695a,
    name: "home-currentFlyer"
  }, {
    path: "/home/downloadCenter",
    component: _23e3cfe5,
    name: "home-downloadCenter"
  }, {
    path: "/home/EDMS",
    component: _049cfb6d,
    name: "home-EDMS"
  }, {
    path: "/home/FAQs",
    component: _533c6db6,
    name: "home-FAQs"
  }, {
    path: "/home/loyaltyProgram",
    component: _d2371874,
    name: "home-loyaltyProgram"
  }, {
    path: "/home/myDetail",
    component: _80a39a46,
    name: "home-myDetail"
  }, {
    path: "/home/newsLetter",
    component: _59435fc1,
    name: "home-newsLetter"
  }, {
    path: "/home/privacyPolicy",
    component: _17393cbc,
    name: "home-privacyPolicy"
  }, {
    path: "/home/projects",
    component: _60eec2e2,
    name: "home-projects"
  }, {
    path: "/home/refundReturns",
    component: _2c306cf3,
    name: "home-refundReturns"
  }, {
    path: "/home/termsConditions",
    component: _1f075be7,
    name: "home-termsConditions"
  }, {
    path: "/home/video",
    component: _52fe027a,
    name: "home-video"
  }, {
    path: "/home/myDetail/enquiryDetail",
    component: _43346e02,
    name: "home-myDetail-enquiryDetail"
  }, {
    path: "/home/myDetail/orderDetail",
    component: _3041028a,
    name: "home-myDetail-orderDetail"
  }, {
    path: "/home/myDetail/components/orderDetailPart",
    component: _4d1786c7,
    name: "home-myDetail-components-orderDetailPart"
  }, {
    path: "/",
    component: _bdb19ece,
    name: "index"
  }, {
    path: "/3D/:id?",
    component: _67d9a2f0,
    name: "3D-id"
  }, {
    path: "/article/:type?",
    component: _d76182a8,
    name: "article-type"
  }, {
    path: "/category/:firstCategory",
    component: _cb19b828,
    name: "category-firstCategory"
  }, {
    path: "/design/:id?",
    component: _e4d3027a,
    name: "design-id"
  }, {
    path: "/news/:newsName?",
    component: _be08439e,
    name: "news-newsName"
  }, {
    path: "/pdf/:id?",
    component: _52e28a91,
    name: "pdf-id"
  }, {
    path: "/product-builder/:code",
    component: _a68c7690,
    name: "product-builder-code"
  }, {
    path: "/product-record/:code",
    component: _80928478,
    name: "product-record-code"
  }, {
    path: "/product/:code",
    component: _529b7374,
    name: "product-code"
  }, {
    path: "/qrcode/:id?",
    component: _f296f21a,
    name: "qrcode-id"
  }, {
    path: "/render/:id?",
    component: _4feb51eb,
    name: "render-id"
  }, {
    path: "/category/:firstCategory/:secondCategory",
    component: _6eac5d52,
    name: "category-firstCategory-secondCategory"
  }, {
    path: "/orderShare/:aid?/:id?",
    component: _df26395a,
    name: "orderShare-aid-id"
  }, {
    path: "/category/:firstCategory/:secondCategory/:thirdCategory",
    component: _70810a91,
    name: "category-firstCategory-secondCategory-thirdCategory"
  }, {
    path: "/orderShare/:uid?/:aid?/:id?",
    component: _9c4f3cbc,
    name: "orderShare-uid-aid-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
